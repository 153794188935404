<template>
  <div style="padding: 15px; position: relative">
    <div style="padding-bottom: 70px">
      <el-row>
        <span
          style="
            font-size: 16px;
            margin-right: 15px;
            color: #333;
            font-weight: bold;
          "
          >当前简历</span
        >
        <el-button
          @click="createNew()"
          style="font-size: 12px; padding: 6px 15px 8px"
          >创建为新简历</el-button
        >
      </el-row>

      <item-resume v-if="current" :data="current" style="margin-top: 10px">
      </item-resume>

      <el-row style="margin-top: 15px">
        <span
          style="
            font-size: 16px;
            margin-right: 15px;
            color: #333;
            font-weight: bold;
          "
          >疑似重复简历</span
        >
        <span style="font-size: 14px; color: #e58a00"
          >(鼠标悬浮在卡片后勾选要合并的简历)</span
        >
      </el-row>

      <item-resume
        v-for="(info, index) in repeatedResumeInfos"
        :key="index"
        :enableCheck="true"
        @check="check"
        :index="index"
        :data="info"
        style="margin-top: 10px"
      >
      </item-resume>
    </div>
    <div
      style="
        position: fixed;
        height: 50px;
        z-index: 6;
        line-height: 50px;
        bottom: 20px;
        width: calc(100% - 40px);
        border-top: 1px solid #e5e5e5;
        left: 20px;
        background-color: #f0f0f0;
      "
    >
      <div style="text-align: center">
        <el-button
          type="primary"
          @click="sub()"
          :disabled="checked.length == 0"
          size="mini"
          style="margin-right: 10px"
          >智能合并</el-button
        >
        <el-popover
          placement="bottom-start"
          trigger="hover"
          popper-class="quickPipeline"
        >
          <div style="margin-left: 5px">
            <div class="title">提示：</div>
            <div class="content">
              智能合并会把所有简历中最新的信息合并到一份简历中
            </div>
          </div>
          <i
            class="iconfont icon-wenhao-xiangsu"
            style="
              font-size: 16px;
              margin-left: 4px;
              color: #999999;
              cursor: pointer;
            "
            slot="reference"
          ></i>
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script>
import ItemResume from "./componets/itemResume";
import { getRepeatedResumeInfos, postMergeResume } from "../../api/api";
export default {
  name: "mergeResume",
  components: { ItemResume },
  props: {
    id: {
      type: Number,
      default: 0,
    },
    model: { type: Object, default: null },
  },
  watch: {
    id: {
      handler(v) {
        this.getData();
        this.checked = [];
        alert(JSON.stringify(this.checked));
      },
      deep: true,
    },
  },
  data() {
    return {
      current: null,
      repeatedResumeInfos: [],
      checked: [],
      saveLoading: false,
    };
  },
  methods: {
    check(index, res) {
      if (res) {
        if (this.checked.indexOf(index) == -1) {
          this.checked.push(index);
        }
      } else {
        if (this.checked.indexOf(index) > -1) {
          let ind = this.checked.indexOf(index);
          this.checked.splice(ind, 1);
        }
      }
    },
    getData() {
      getRepeatedResumeInfos({ resumeId: this.id }).then((res) => {
        if (res.success) {
          this.current = res.result.newResumeInfo;
          this.repeatedResumeInfos = res.result.repeatedResumeInfos;
        }
      });
    },

    createNew() {
      this.$emit("handleNewResume", this.id);
    },
    sub() {
      this.$confirm("确定合并简历？合并后会跳转到简历详情", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "altCls",
        type: "warning",
      })
        .then(() => {
          let mergeResumeIds = [];
          for (let check in this.checked) {
            mergeResumeIds.push(this.repeatedResumeInfos[check].id);
          }
          postMergeResume({
            currentResumeId: this.id,
            mergeResumeIds: mergeResumeIds,
          }).then((res) => {
            if (res.success) {
              this.$message.success("合并成功");
              this.$emit("handleSuccess", res.result);
            }
          });
        })
        .catch(() => {});
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
