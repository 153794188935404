<template>
  <div class="itemCard">
    <el-card shadow="none" class="shadowCard">
      <el-checkbox :value="has_check" @change="checked">
        <div
          class="personFoo"
          style="display: flex; justify-content: space-between"
        >
          <div style="display: flex">
            <div
              style="display: inline-block; cursor: pointer"
              class="cardHover"
            >
              <p
                @mouseover="showDetail(data.id)"
                style="
                  margin: 0;
                  font-weight: bold;
                  float: left;
                  line-height: 20px;
                  font-size: 16px;
                "
                class="resumeName tabHref"
                @mouseout="clearTime()"
              >
                <span v-if="data.name">{{ data.name }}</span>
                <span v-if="data.name_pinyin">({{ data.name_pinyin }})</span>
                <span v-else>&nbsp;</span>
              </p>

              <span class="fontColor garyColor" v-if="data.sex">
                . {{ data.sex }}</span
              >
              <span class="fontColor garyColor" v-if="data.age > 0">
                . {{ data.age }}岁</span
              >
              <span class="fontColor garyColor" v-if="data.married">
                . {{ data.married }}</span
              >
              <span class="fontColor garyColor" v-if="data.experience">
                . {{ data.experience }}年工作经验</span
              >
              <template v-if="data.nowLocationName">
                .
                <span
                  class="fontColor garyColor"
                  v-html="data.nowLocationName"
                ></span>
              </template>
            </div>
            <span
              class="button"
              type="danger"
              size="small"
              v-if="data.channel"
              style="
                font-size: 12px;
                margin-left: 8px;
                padding: 0 5px;
                line-height: 18px;
              "
            >
              {{ data.channel }}
            </span>
            <span
              class="button1"
              type="danger"
              size="small"
              v-if="data.contactStatus"
              style="
                font-size: 12px;
                margin-left: 8px;
                padding: 0 5px;
                line-height: 18px;
              "
            >
              {{ data.contactStatus }}
            </span>
          </div>
          <span class="secfontColor fr">
            <span style="font-size: 12px">{{ data.ownerName }} </span>
            <span v-if="data.createdTime" style="font-size: 12px">
              {{ data.createdTime }}创建
            </span>
            <el-divider direction="vertical"></el-divider>
            <span v-if="data.lastModifiedTime" style="font-size: 12px">
              {{ data.lastModifiedTime }}更新</span
            >
          </span>
        </div>
        <div
          class="fontColor"
          style="padding: 10px 0px 5px"
          :class="
            data.employmentsList.length == 0
              ? 'contactInformation'
              : 'contactInformationBoreder'
          "
          v-if="
            (data.mobile + data.email + data.wechatOrOther + data.virtual_phone)
              .length > 0
          "
        >
          <span
            class="garyColor"
            style="margin-right: 25px"
            v-show="data.mobile || data.virtual_phone"
          >
            <i
              v-if="data.mobile || data.virtual_phone"
              class="iconfont fontColor icon-rc_sj garyColor"
              @click="call(data.mobile)"
            ></i>
            {{
              isShow
                ? data.mobile
                  ? data.mobile
                  : data.virtual_phone
                : "***********"
            }}
            <el-tag
              size="mini"
              v-if="
                data.mobile && data.virtual_phone
                  ? false
                  : data.virtual_phone
                  ? true
                  : false
              "
              >虚拟号</el-tag
            >
          </span>
          <span
            style="margin-right: 25px"
            v-show="data.email"
            class="garyColor"
          >
            <i
              style="font-size: 12px"
              class="iconfont fontColor icon-rc_yx garyColor"
            ></i>
            {{ isShow ? data.email : "***********" }}
          </span>
          <span v-show="data.wechatOrOther" class="garyColor">
            <i
              style="font-size: 12px"
              class="iconfont fontColor icon-rc_wx garyColor"
            ></i>
            {{ isShow ? data.wechatOrOther : "***********" }}
          </span>
          <p
            class="secfontColor fr iconHover"
            style="font-size: 12px; display: flex"
            @click="hideContact()"
          >
            <i class="iconfont secfontColor icon-rc_eye1" v-if="isShow"></i>
            <i class="iconfont secfontColor icon-rc_eye2" v-if="!isShow"></i>
            <span style="margin-left: 8px">
              {{ isShow ? "隐藏" : "显示" }}</span
            >
          </p>
        </div>
        <div
          v-else-if="
            data.employmentsList.length > 0 || data.educationsList.length > 0
          "
          style="width: 100%; height: 20px"
        ></div>

        <!--              工作情况-->
        <el-col
          class="workStatus clearfix"
          v-if="
            data.employmentsList.length > 0 || data.educationsList.length > 0
          "
        >
          <el-col :span="12" style="padding-left: 0">
            <el-col
              :span="24"
              v-for="j in data.employmentsList"
              :key="j.id"
              style="margin-bottom: 2px; padding: 0"
              v-if="j.company"
            >
              <div>
                <span class="text-overflows fontsec infoContent">
                  <fixed-length-span
                    type="text"
                    class="linkButton fontsec"
                    :width="300"
                    :text="j.company"
                  ></fixed-length-span>
                  <template v-if="j.title" class="fontsec">
                    · <span>{{ j.title }}</span>
                  </template>
                </span>
                <span
                  class="fontColor fontsec"
                  style="
                    width: 170px;
                    font-size: 13px;
                    line-height: 21px;
                    margin-left: 20px;
                  "
                  v-if="j.startDate || j.endDate || j.isSoFar"
                  >{{ j.startDate }} 至
                  {{ j.isSoFar ? "至今" : j.endDate }}</span
                >
              </div>
            </el-col>
          </el-col>
          <el-col :span="12" class="fontsec" style="padding-left: 0">
            <el-col
              :span="24"
              v-for="m in data.educationsList"
              :key="m.id"
              style="margin-bottom: 2px"
            >
              <div v-if="m.school">
                <span
                  :title="m.school + (m.education ? ' · ' + m.education : '')"
                  class="text-overflows infoContent"
                >
                  <fixed-length-span
                    class="linkButton fontsec"
                    :text="m.school"
                    :width="300"
                  ></fixed-length-span>
                  <span v-if="m.education" class="fontsec">
                    · {{ m.education }}</span
                  >
                </span>
                <span
                  class="fontColor fontsec"
                  style="
                    width: 170px;
                    font-size: 13px;
                    line-height: 21px;
                    margin-left: 20px;
                  "
                  v-if="m.startDate || m.endDate || m.isSoFar"
                  >{{ m.startDate }} 至
                  {{ m.isSoFar ? "至今" : m.endDate }}</span
                >
              </div>
            </el-col>
          </el-col>
        </el-col>
      </el-checkbox>
    </el-card>

    <el-dialog
      :visible.sync="isShowDetail"
      append-to-body
      :close-on-click-modal="true"
      width="1300px"
      custom-class="floatwindow"
      top="5vh"
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-rc_bt"></i>人才
      </div>

      <resumeDetail
        v-if="isShowDetail"
        :resumeDetailId="resumeId"
        :hover="hoverInfo"
        :repeatResume="repeatResume"
      ></resumeDetail>
    </el-dialog>
  </div>
</template>

<script>
import resumeDetail from "../../../components/resumeDetail";
import FixedLengthSpan from "../../../components/common/FixedLengthSpan";

export default {
  components: {
    FixedLengthSpan,
    resumeDetail,
  },
  data() {
    return {
      isShow: false,
      has_check: false,
      isShowDetail: false,
      resumeId: 0,
      repeatResume: { type: "列表", valId: "" },
      hoverInfo: { matchAll: false, majorSearch: false, hover: false },
      time_range: false,
    };
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    enableCheck: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    hideContact() {
      this.isShow = !this.isShow;
    },
    checked() {
      this.has_check = !this.has_check;
      this.$emit("check", this.index, this.has_check);
    },
    showDetail(id) {
      this.time_range = true;
      let that = this;
      setTimeout(function () {
        if (that.time_range) {
          that.isShowDetail = true;
          that.resumeId = id;
        }
      }, 2000);
    },
    clearTime() {
      this.time_range = false;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.itemCard {
  &:hover {
    background: #f5f7fa;
    box-shadow: none;
  }
}
.resumeName {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  //   span{
  //  &:hover {
  //     text-decoration: underline;
  //   }
  //   }
}

.input-with-select.appandSelect {
  vertical-align: middle !important;
  .el-input-group__append {
    background-color: #fff;
    width: 65px;
    border-radius: 0;
  }
  .el-select__caret {
    height: 32px;
    line-height: 32px;
  }
  .el-input-group__append:hover {
    border-color: #ededed !important;
  }
}
.input-with-select.appandSelect:hover {
  .el-input-group__append {
    border-color: #ededed !important;
  }
}
.searchM {
  margin-bottom: 5px;
  .el-form-item {
    float: left;
    margin-bottom: 15px;
  }
}
.shadowCard {
  border-radius: 0;
}

.shadowCard:hover {
  .triangle-topleft {
    display: inline-block;
  }
}
.el-card :deep(.el-checkbox) {
  display: flex;
  align-items: center;
  width: calc(100% - 0px);
}
.el-card :deep(.el-checkbox__input) {
  margin: 0 10px;
}
.el-card :deep(.el-checkbox__label) {
  width: calc(100% - 0px);
}
.el-card:hover {
  background: #f5f7fa;
  box-shadow: none;
}
.triangle-topleft {
  display: none;
  width: 0;
  height: 0;
  left: 0;
  position: absolute;
  cursor: pointer;
  border-top: 30px solid #e7e7e7;
  border-right: 30px solid transparent;
}
.triangle-topleft::before {
  content: "\e6da";
  color: white;
  left: 2px;
  font-family: element-icons !important ;
  position: relative;
  top: -28px;
}
.searchL {
  width: calc(100% - 162px);
}
.search {
  height: 35px;
  line-height: 35px;
  :deep(.el-input-group__prepend) {
    border-left: 1px solid #e60012;
    border-top: 1px solid #e60012;
    border-bottom: 1px solid #e60012;
    line-height: 33px;
    background-color: white;
  }
  :deep(.el-input__inner) {
    height: 35px !important;
    line-height: 35px !important;
    border-color: #e60012 !important;
    border-right: none;
    border-left: none;
  }
  :deep(.el-input-group__append) {
    border-color: #e60012 !important;
    background-color: #e60012;
    height: 33px !important;
    line-height: 33px !important;
    color: #fff;
    font-weight: bold;
  }
  :deep(.el-input-group__append:hover) {
    background-color: #cc0000;
  }
}
.other {
  line-height: 35px;
  font-size: 13px;
  float: right;
  span {
    margin: 0;
    cursor: pointer;
    color: #606266;
    &:hover {
      color: #e60012;
    }
  }

  .more {
    padding-left: 8px;
    i {
      margin-left: 8px;
    }
  }
}
.tableType {
  display: inline-block;
}
.comList {
  max-height: 400px;
  overflow: auto;
  li {
    height: 32px;
    line-height: 32px;
    padding: 0 35px 0 15px;
    cursor: pointer;
    &:hover {
      background-color: #f0f0f0;
    }
    .comName {
      width: 80%;
      float: left;
      p {
        max-width: 60%;
        float: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      span {
        height: 20px;
        width: 62px;
        text-align: center;
        line-height: 20px;
        display: inline-block;
        color: #efa14d;
        border: 1px solid #efa14d;
        border-radius: 3px;
        margin-left: 8px;
      }
    }
  }
}

.loacalStorage {
  border: 1px solid #ededed;
  width: calc(79% - 350px);
  z-index: 10;
  padding: 15px;
  background: white;
  position: absolute;
  top: 35px;
  left: 103px;
}
.radioGroupBtn {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 0 !important;
  .el-checkbox-button {
    box-shadow: none !important;
    .el-checkbox-button__inner {
      border-radius: 0;
      border: 0;
      padding: 7px 10px;
      color: #666;
      font-size: 14px !important;
    }
  }
  .el-checkbox-button.is-checked {
    .el-checkbox-button__inner {
      color: #fff;
    }
  }
}
.linkButton {
  color: #606266 !important;
}
.iconHover:hover {
  cursor: pointer;
  color: #ff3f3f !important;
  .secfontColor {
    color: #ff3f3f !important;
  }
}
</style>

    <style scoped>
.searchInfo :deep(.el-select .el-tag) {
  overflow: hidden;
  max-width: 120px;
  text-overflow: ellipsis;
}
.btnHover {
  color: rgb(51, 51, 51);
  font-size: 13px;
  cursor: pointer;
}
.btnHover:hover {
  color: #ff3f3f !important;
  text-decoration: none;
}
.changeButtonStyle {
  padding: 6px;
  color: white;
  background: #ff3f3f;
}
.content-container :deep(.el-input .el-select .el-input) {
  width: 100px;
  padding-left: 8px;
}
.labelAppend :deep(.el-input-group__append) {
  border: none;
  padding: 0;
  width: 28px;
  background-color: white;
  position: absolute;
  right: 0;
  top: 1px;
}
.table-top {
  margin-bottom: 15px;
  margin-top: 15px;
}
.backgroundColor {
  margin-right: 25px;
}

.contactInformationBoreder {
  padding: 15px 0;
  /* margin-left: 20px; */
}
.contactInformation,
.workStatus {
  /* padding-left: 20px !important; */
}
.workStatus {
  width: calc(100% - 52px);
  padding: 0 0 5px !important;
  /* margin-left: 22px;
  margin-right: 22px; */
  /* border-top: 1px solid #efefef; */
}
.order {
  padding: 0 8px;
  color: #606266;
  cursor: pointer;
}
.hoverColorChange {
  color: #606266;
  cursor: pointer;
}
.searchBox :deep(.el-checkbox-button__inner) {
  border: 0;
}
.searchBox {
  padding: 0 !important;
}
.bodTop {
  border-top: 30px solid #ff3f3f !important;
}
.personFoo {
  padding: 15px 0 0px 0;
}
.searchRow :deep(.el-input .el-select .el-input) {
  width: 110px;
  padding-left: 10px;
}
.searchRow {
  padding-left: 0 !important;
}
.shadowCard :deep(.el-card__body) {
  padding: 0 20px 15px 0 !important;
  position: relative;
  display: flex;
}
.fontsec {
  font-size: 13px !important;
  color: #898989 !important;
}
.garyColor {
  color: #333;
}
.secfontColor {
  color: #999;
}
</style>
<style  lang="scss">
.floatwindow {
  width: 1300px;
  margin-top: 0 !important;
  margin-right: 0;
  height: 100vh;
  @media (max-width: 1366px) {
    width: 900px;
    // margin-top: 3% !important;
  }
  .el-dialog__body {
    // height: 645px;
    overflow: scroll;
    padding: 0;
  }
  .drawerTitle {
    font-weight: bold;
    .drawIcon {
      color: #898989;
      font-weight: normal;
      vertical-align: bottom;
      margin-right: 8px;
    }
  }
}
</style>