<template>
  <div class="wrapper">
    <el-row class="main">
      <!--导航菜单-->
      <resumeMenu :actived="actived" ref="menu"></resumeMenu>
      <section
        class="content-container clearfix"
        :class="collapsed ? 'screen' : ''"
      ></section>
    </el-row>

    <div style="width: 100%; padding-bottom: 15px">
      <div
        style="
          width: calc(100% - 275px);
          background-color: white;
          margin-right: 10px;
          padding: 20px 0px;
          margin-left: 260px;
          border: 1px #e5e5e5 solid;
        "
      >
        <div style="width: 100%">
          <div style="float: left; margin-bottom: 10px">
            <el-button
              type="default"
              plain
              size="mini"
              style="margin: 0px 10px"
              @click="delAll"
              :disabled="checkedIds.length == 0"
            >
              <i class="el-icon-delete"></i> 批量删除
            </el-button>
          </div>
          <div style="float: right; padding-bottom: 10px">
            <simple-pagination :page="page" v-model="page"></simple-pagination>
          </div>
        </div>

        <el-table
          :header-cell-style="{
            'background-color': '#f5f7fa',
            height: '35px',
          }"
          class="tabBorder custor"
          v-loading="listLoading"
          ref="tableList"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          :data="list"
        >
          <el-table-column fixed="left" type="selection" width="45">
          </el-table-column>
          <div v-for="(field, index) in fields" :key="index">
            <simple-table-column
              @showDetail="showDetail"
              :field="field"
            ></simple-table-column>
          </div>

          <el-table-column label="操作" align="left">
            <template slot-scope="scope">
              <el-button
                @click="handle(getTypeKey(scope.row.resumeType), scope.row)"
                type="text"
                size="small"
                style="color: #526ecc"
                >{{ getTypeKey(scope.row.resumeType) }}</el-button
              >

              <el-divider direction="vertical"></el-divider>
              <el-button
                @click="remove(scope.row.id)"
                type="text"
                size="small"
                style="color: #526ecc"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <div style="width: 100%; display: block; height: 40px">
          <div style="float: right; padding-top: 5px">
            <simple-pagination :page="page" v-model="page"></simple-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-drawer
      v-if="showDialog"
      :visible.sync="showDialog"
      append-to-body
      :modal="false"
      custom-class="drawerClass"
      :close-on-click-modal="false"
      direction="ttb"
      :before-close="handleClose"
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-rc_hbcfjl"></i>合并重复简历
      </div>

      <merge-resume
        @handleNewResume="handleNewResume"
        @handleSuccess="handleSuccess"
        :model="model"
        :id="current_id"
      ></merge-resume>
    </el-drawer>

    <el-drawer
      v-if="isShowDetail"
      :visible.sync="isShowDetail"
      append-to-body
      :modal="false"
      custom-class="drawerClass"
      :close-on-click-modal="false"
      direction="ttb"
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-rc_bt"></i>人才
      </div>

      <resumeDetail
        v-if="isShowDetail"
        :resumeDetailId="resumeId"
        :hover="hoverInfo"
        :repeatResume="repeatResume"
      ></resumeDetail>
    </el-drawer>

    <el-drawer
      v-if="isShowEdit"
      :visible.sync="isShowEdit"
      append-to-body
      :modal="false"
      custom-class="drawerClass"
      :close-on-click-modal="false"
      direction="ttb"
      :before-close="resumeInfoClose"
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-rc_bt"></i>编辑人才信息
      </div>
      <div class="drawerCon">
        <resumeInfo
          v-if="isShowEdit"
          :resumeId="resumeId"
          :path="true"
          @cleanAll="cleanAll"
          ref="saveCusInfo"
        ></resumeInfo>

        <div class="drawerCon_footer fixedFoot">
          <el-button type="default" plain size="mini" @click="hideEdit()"
            >取消</el-button
          >
          <el-button
            type="primary"
            size="mini"
            :loading="saveloading"
            @click="saveInfos()"
            style="margin-right: 15px"
            >保存</el-button
          >
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import resumeMenu from "./resumeMenu";
import SimpleTableColumn from "../../components/common/SimpleTableColumn";
import SimplePagination from "../../components/common/SimplePagination";
import resumeDetail from "../../components/resumeDetail";
import PendingModel from "./model/PendingModel";
import MergeResume from "./mergeResume";
import resumeInfo from "../../components/resumeInfo";

let model = new PendingModel();

export default {
  components: {
    MergeResume,
    SimplePagination,
    SimpleTableColumn,
    resumeMenu,
    resumeDetail,
    resumeInfo,
  },
  data() {
    let recordId = this.$route.query.recordId || 0;
    return {
      listLoading: true,
      collapsed: "upDateCollapsed",
      actived: "待处理人才",
      model: model,
      fields: model.getFields(),
      list: [],
      page: {
        current: 1,
        total: 0,
        size: 50,
      },
      showDialog: false,
      current_id: 0,
      isShowDetail: false,
      resumeId: 0,
      repeatResume: { type: "列表", valId: "" },
      hoverInfo: { matchAll: true, majorSearch: false, hover: true },
      isShowEdit: false,
      saveloading: false,
      recordId: recordId,
      checkedIds: [],
    };
  },
  name: "record",
  watch: {
    page(v, ov) {
      this.getData(this.getFilterKey());
    },
    isShowEdit(newValue) {
      if (newValue) {
        this.noScroll(); //禁止滚动
      } else {
        this.canScroll();
      }
    },
    isShowDetail(newValue) {
      if (newValue) {
        this.noScroll(); //禁止滚动
      } else {
        this.canScroll();
      }
    },
    showDialog(newValue) {
      if (newValue) {
        this.noScroll(); //禁止滚动
      } else {
        this.canScroll();
      }
    },
  },
  methods: {
    handle(type, data) {
      if (type == "重复") {
        this.showDialog = true;
        this.current_id = data["id"];
      }
      if (type == "完善") {
        this.isShowEdit = true;
        this.resumeId = data["id"];
      }
    },
    handleClose(done) {
      done();
    },
    resumeInfoClose(done) {
      done();
      this.saveloading = false;
      this.getData();
    },
    //合并成功之后回调函数
    handleSuccess(resumeId) {
      this.resumeId = resumeId;
      this.showDialog = false;
      this.isShowDetail = true;
      this.getData();
    },
    //创建为新简历之后的回调函数
    handleNewResume(resumeId) {
      this.resumeId = resumeId;
      this.showDialog = false;
      this.isShowEdit = true;
    },
    getTypeKey(k) {
      let s = k;
      switch (k) {
        case "有重复":
          s = "重复";
          break;
        case "需完善":
          s = "完善";
          break;
      }
      return s;
    },
    getFilterKey() {
      return {
        recordId: this.recordId,
        ...this.page,
      };
    },
    showDetail(v) {
      this.isShowDetail = true;
      this.resumeId = v;
    },
    getData() {
      window.scrollTo(0, 0);
      this.listLoading = true;
      model.getData(this.getFilterKey()).then((res) => {
        this.listLoading = false;
        if (res.success) {
          this.list = res.result.records;
          this.page.size = res.result.size;
          this.page.total = res.result.total;
        }
      });
    },
    hideEdit() {
      this.isShowEdit = false;
    },
    saveInfos() {
      this.saveloading = true;
      this.$refs.saveCusInfo
        .saveResume()
        .then((value) => {
          // debugger
          this.saveloading = false;
          this.isShowEdit = false;
          this.getData();
        })
        .catch(() => {
          this.saveloading = false;
        });
    },
    cleanAll(val) {
      if (val) {
        this.saveloading = false;
        this.isShowEdit = false;
        this.getData();
      } else {
        this.saveloading = false;
      }
    },
    remove(id) {
      this.$confirm("是否删除该简历？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "altCls",
        type: "warning",
      })
        .then(() => {
          let ids = [];
          ids.push(id);
          model.remove(ids).then((res) => {
            if (res.success) {
              this.getData();
            }
          });
        })
        .catch(() => {});
    },
    handleSelectionChange(val) {
      this.checkedIds = [];
      for (let ind in val) {
        this.checkedIds.push(this.list[ind].id);
      }
    },
    delAll() {
      this.$confirm("是否删除选中数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "altCls",
        type: "warning",
      })
        .then(() => {
          model.remove(this.checkedIds).then((res) => {
            if (res.success) {
              this.$message.success("删除成功");
              this.getData();
            }
          });
        })
        .catch(() => {});
    },
  },

  created() {
    if (this.$route.query.resumeId) {
      this.showDialog = true;
      this.current_id = this.$route.query.resumeId;
    }
  },

  mounted() {
    this.getData(this.getFilterKey());
  },
};
</script>

