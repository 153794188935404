import Model from "../../../model/Model";
import Field from "../../../model/Field";
import SpanColumn from "../../../model/SpanColumn";

export default class PendingModel extends Model{

    remove(ids){
        let params = {ids:ids}
        return this.postRequest('/resume/resume/to-complete/delete',params)
    }
    getData(t){
        let params = typeof t == 'undefined' ? {} : t
        return this.getRequest('/resume/resume/to-complete',params)
    }

    getFields(k) {
       return [
            new Field('name','人才姓名')
                .column(new SpanColumn().align("left")),
            new Field('lastModifiedTime','最新编辑时间')
               .column(new SpanColumn().align("left")),
            new Field('createdTime','添加时间')
               .column(new SpanColumn().align("left")), 
       ]
    }

}
